import React, { Component } from 'react';
import Octicon, {Home, Law, Key} from "@primer/octicons-react";
import styles from "./_landing.module.scss";

export default class LandingPage extends Component {
  render () {
    return (
      <div>
        <div className={styles.landing_content}>
          <div className={styles.item}>
            <Octicon
              className={styles.icon}
              icon={Home}
              size="large"
            />
            <h3>Kuluttajat</h3>
            <p>Löydä paikalliset kiinteistön- ja vuokravälitysliikkeet</p>
          </div>
          <div className={styles.item}>
            <Octicon
              className={styles.icon}
              icon={Law}
              size="large"
            />
            <h3>Viranomaiset</h3>
            <p>Käyttävät välitysliikerekisteriä valvontaan</p>
          </div>
          <div className={styles.item}>
            <Octicon
              className={styles.icon}
              icon={Key}
              size="large"
            />
            <h3>Välitysliikkeet</h3>
            <p>Tarkista liikkeesi tietojen ajantasaisuus</p>
          </div>
        </div>
        <div className={styles.landing_infotext}>
          <p>
            Rekisteri kiinteistönvälitysliikkeistä ja vuokrahuoneistojen välitysliikkeistä on julkinen ja jokaisella on oikeus saada siitä tietoja.  
          </p>
          <p>
            Välitysliikerekisteri sisältää henkilötietoja. Henkilötietojen suojaamiseksi aluehallintovirasto ei julkaise koko rekisterin tietoja yleisölle tarjottavan katseluyhteyden välityksellä. Voit kuitenkin hakea verkkosivulta yrityksen yritys- ja yhteisötunnuksella (y-tunnus) tiedon siitä, onko kyseinen yritys (yksityinen elinkeinonharjoittaja tai oikeushenkilö) rekisteröitynyt välitysliikerekisteriin. Jos yritys on rekisteröitynyt välitysliikerekisteriin, saat myös tiedon siitä, onko se rekisteröity kiinteistönvälitysliikkeeksi vai vuokrahuoneiston välitysliikkeeksi. Tarvittaessa saat selville tietyn yrityksen y-tunnuksen yritys- ja yhteisötietojärjestelmästä: <a href='https://ytj.fi'>ytj.fi</a>.
          </p>
          <p>
            Muita välitysliikerekisterin tietoja voit tiedustella aluehallintoviraston asiakaspalvelusta esim. <a href='https://avi.service-now.com/avi?id=avi_common_sc_cat_item&sys_id=be4984271bbb60502fc6400de54bcbbc'>Kysy avin aspalta -lomakkeella.</a> <a href='https://avi.fi/asiakaspalvelu'>Aluehallintoviraston asiakaspalvelu.</a>
          </p>
        </div>      
      </div>   
    )
  }
}