import React from 'react';
import styles from './_table-styles.module.scss'
import classNames from "classnames";

const ListItem = props => {
  const className = classNames(
    styles.table_details_wrapper
  );

  return (
    <div
      id={'item' + props.broker.id}
      className={styles.table_row}
      role="rowgroup"
      tabIndex="0"
    >
      <div
        className={className}
        role="row"
      >
        <span role="cell">
          {props.broker.businessId}
        </span>
        <span className="d-none d-md-block" role="cell">
          {props.broker.brokeringForm}
        </span>
        <span className="d-none d-md-block" role="cell">
          {props.broker.avi}
        </span>
      </div>
    </div>);
};

export default ListItem;