// IE11 polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Footer } from './components/footer/Footer'
import registerServiceWorker from './registerServiceWorker';
import './index.scss';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const WithFooter = WrappedComponent => () => [
  <WrappedComponent key="1" />,
  <Footer key="2" />
];

const Main = () => (
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);

const AppWithFooter = WithFooter(Main);

ReactDOM.render(
  <AppWithFooter/>,
  rootElement);

registerServiceWorker();
