import React, { Component } from 'react';
import styles from './_footer.module.scss';

export class Footer extends Component {
  render () {
    return (
      <footer className={styles.footer}>
        <p className={styles.footer_detail}>Lue lisää <a href="https://avi.fi/asioi/yritys-tai-yhteiso/luvat-ilmoitukset-ja-hakemukset/kiinteistonvalitys">Aluehallintoviraston</a> sivuilta | <a href="http://www.avi.fi/web/avi/tietosuoja">Tietosuoja</a> | <a target="_blank" href="documents/VASA_saavutettavuusseloste.html">Saavutettavuusseloste</a></p>
        <p className={styles.footer_detail}>&copy; {new Date().getFullYear()} Aluehallintovirasto</p>
      </footer>
    )
  }
}