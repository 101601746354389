import React, {Component} from 'react';
import {fetchBrokers} from './Api'
import classNames from "classnames";
import { enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {Container, Row, Col, Input} from "reactstrap";
import Octicon, {Search} from "@primer/octicons-react";
import LandingPage from './landing/LandingPage'
import BrokerTable from './table/BrokerTable';
import './_main.scss';

export class Main extends Component {
  static displayName = Main.name;
  targetElement = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: null,
      data: null,
      broker: null
    };
  }

  resetData = () => {
    this.setState({
      data: null,
      broker: null,
      error: null
    })
  };

  searchHandleChange = (e) => {
    if (e.target.value.length >= 9) {
      this.setState({
        loading: true
      });
      fetchBrokers(e.target.value, this.searchSuccess, this.searchError)
    } else {
      this.resetData();

      if (document.body.classList.contains('details_open')) {
        enableBodyScroll(this.targetElement);
        document.body.classList.remove('details_open');
      }
    }
  };

  searchSuccess = response => {
    this.setState({
      data: response.data,
      error: null,
      loading: false
    });
  };

  searchError = response => {
    this.setState({
      error: response,
      loading: false
    });
  };

  componentWillUnmount = () => {
    clearAllBodyScrollLocks();
  }

  renderContent = () => {
    if (this.state.data || this.state.loading) {
      return <div className="body_container">
        <BrokerTable
          data={this.state.data}
          loading={this.state.loading}
        />
      </div>
    } else {
      return <LandingPage/>
    }
  };

  render() {
    const searchContainer = classNames(
      "search_container",
      "bg-primary"
    );

    const searchBar = classNames(
      "search_bar",
      "border-5",
      "text-primary"
    );

    const placeholderText = "Hae välitysliikettä Y-tunnuksella";

    return (
      <div id="main_content">
        <Container fluid className="search_container_wrapper">
          <Row className={searchContainer}>
            <Col md="11" lg="9" xl="8">
              <div
                className="search_input_wrapper"
                role="search"
              >
                <Octicon
                  className="search_icon"
                  size="medium"
                  icon={Search}
                />
                <Input
                  className={searchBar}
                  type="search"
                  name="search"
                  placeholder={placeholderText}
                  value={this.state.search}
                  onChange={this.searchHandleChange}
                  aria-label={placeholderText}
                />
              </div>
            </Col>
          </Row>
        </Container>
        {this.renderContent()}
      </div>
    );
  }
}