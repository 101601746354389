import React, { Component } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import logoUrl from '../../img/logo.svg';
import './_header.scss';

export class Header extends Component {
  static displayName = Header.name;

  render () {
    return (
      <header className="header">
        <Navbar className="p-4 justify-content-between">
          <NavbarBrand tag={Link} to="/" className="m-0 p-0">
            <img className="logo" src={logoUrl} alt="Aluehallintovirasto välitysliikerekisteri logo"/>
          </NavbarBrand>

          <div className="header_text d-none d-md-block">
            Aluehallintoviraston ylläpitämä rekisteri kiinteistövälitysliikkeistä ja vuokrahuoneistojen välitysliikkeistä
          </div>
        </Navbar>
      </header>
    );
  }
}
