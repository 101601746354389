import React, {Component} from 'react';
import classNames from 'classnames'
import styles from './_table-styles.module.scss'

export default class TableColumnHeader extends Component {

  render() {
    const classes = classNames(
      styles.column_label,
      this.props.className
    );

    const {label} = this.props;

    return (
      <span
        className={classes}
        role="columnheader"
      >
        {label}
      </span>
    )
  }
}