import React, {Component} from 'react';
import classNames from "classnames";
import ListItem from "./ListItem";
import TableColumnHeader from './TableColumnHeader'
import styles from './_table-styles.module.scss'

export default class BrokerTable extends Component {
  render () {
    const {data, loading} = this.props;
    const brokerTable = classNames(
      "main_table",
      styles.table
    );

    const renderStateComponent = () => {
      if (loading) {
        return <p>Ladataan...</p>
      } else {
        if(!data.length) {
          return <p>Ei tuloksia</p>
        }
      }
    };

    return (
      <div
        className={brokerTable}
        role="table"
      >
        <div className={styles.table_header} role="row">
          <TableColumnHeader
            label={'Y-tunnus'}
          />

          <TableColumnHeader
            className="d-none d-md-block"
            label={'Välitystoiminnan muoto'}
          />

          <TableColumnHeader
            className="d-none d-md-block"
            label={'Rekisteri- ja valvontaviranomainen'}
          />
        </div>

        <div className={styles.state_container}>
          { renderStateComponent() }
        </div>
        { !loading &&
          data.map((broker, index) => {
            return (<ListItem
              broker={broker}
            />)
          })
        }
      </div>
    )
  }
}