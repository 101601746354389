import React, { Component } from 'react';
import { Header } from './components/header/Header';
import { Main } from './components/Main';
export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <div className="app_wrapper">
        <Header/>
        <Main/>
      </div>
    );
  }
}
