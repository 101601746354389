import axios from 'axios';

const SEARCH_API_URL = '/api/Brokering?searchTerm=';

const fetchdata = async (URL, onSuccess, onError) => {
  try {
    const response = await axios.get(URL);
    onSuccess(response);
  } catch (error) {
    console.error(error);
    onError(error);
  }
}

let timer = 0;
const delay = (callback, ms) => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    callback();
  }, ms || 0);
}

export const fetchBrokers = async (searchTerm, onSuccess, onError) => {
  searchTerm = encodeURI(searchTerm);
  delay(() => fetchdata(`${SEARCH_API_URL}${searchTerm}`, onSuccess, onError), 500);
}